import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './utils/routeNavigation'
import './config/element'
import '@/style/index.scss'
import 'element-ui/lib/theme-chalk/index.css';
// 组件日文化
import ja from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import * as filters from "@/utils/filter"; //全局过滤器
//注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
locale.use(ja)

// import VueGtm from 'vue-gtm';

// Vue.use(VueGtm, {
//     id: 'GTM-PHQ3VJ',//your
// });


// import VConsole from 'vconsole';
// let vconsole = new VConsole();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
