import router from "../router";
import store from "../store";
const whiteList = [
  '/authentication/login',
  '/member/regists',
  '/member/regists/confirm',
  '/member/regists/complete',
  '/member',
  '/member/lostpasswords/confirm',
  '/member/lostpasswords/complete',
]
router.beforeEach((to, from, next) => {
  // localStorage.removeItem("MEMBER_STATION_STORAGE");
  store.commit("updateLoadingShow", false);
  document.title = to.meta.title;
  if(to.query.logout){
    localStorage.removeItem("MEMBER_STATION_STORAGE");
  }
  if(to.query.userinfo){
    let userinfo = JSON.parse(to.query.userinfo)
    sessionStorage.setItem('hideMenu',true)
    if(!store.state.token||store.state.token != userinfo.token){
      store.commit("updateToken", userinfo.token);
      let data = {
        name:userinfo.name,
        prohibitMyPageContract:JSON.parse(userinfo.prohibitMyPageContract),
        prohibitMyPageReserveCancel:JSON.parse(userinfo.prohibitMyPageReserveCancel),
        prohibitMyPageReserveChange:JSON.parse(userinfo.prohibitMyPageReserveChange),
        prohibitMyPageReserveNew: JSON.parse(userinfo.prohibitMyPageReserveNew),
        token:userinfo.token
      }
      store.commit("updateLoginInfo", data);
      store.commit("updateMemberInfo", data);
    }
    
  }

  if (whiteList.indexOf(to.path) != -1) {
    // 在免登页
    // if (store.state.token) {
    //   next({ path: '/' })
    // } else {
    //   next()
    // }
    next()
  } else {
    // 不在免登页
    if (store.state.token) {
      // console.log(from.path,to.path)
      let contractCourseIdspath = ['/reserve/reserves/search','/reserve/reserves/confirmreserve']
      if(!contractCourseIdspath.some(path => { return path == to.path})){
        sessionStorage.removeItem('contractCourseIds')
        sessionStorage.removeItem('finalcontractCourseIds')
      }
      store.dispatch('getMemberInfo')
      if ((to.path == "/reserve/reserves/search" || to.path == "/reserve/reserves/confirmreserve") && store.state.memberInfo.prohibitMyPageReserveNew && !to.query.reserveId) {
        next('/' + '?errinfo=1')
      } else {
        next()
      }
      if (to.path == "/contract" && store.state.memberInfo.prohibitMyPageContract) {
        next('/' + '?errinfo=2')
      } else {
        next()
      }
      if (to.path == "/reserve/reserves/confirmcancel" && store.state.memberInfo.prohibitMyPageReserveCancel) {
        next('/' + '?errinfo=3')
      } else {
        next()
      }
      if (to.path == "/reserve/reserves/search" && store.state.memberInfo.prohibitMyPageReserveChange && to.query.reserveId) {
        next('/' + '?errinfo=4')
      } else {
        next()
      }
      if (to.path == "/reserve" && store.state.memberInfo.prohibitMyPageReserveCancel && store.state.memberInfo.prohibitMyPageReserveChange) {
        next('/' + '?errinfo=5')
      } else {
        next()
      }
    } else {
      if (to.path != '/authentication/login') {
        sessionStorage.setItem('beforeloginpath', to.path)
        next('/authentication/login')
      }

    }
  }
})


// router.afterEach((to, from) => {
//   (function (i, s, o, g, r, a, m) {
//     i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
//       (i[r].q = i[r].q || []).push(arguments)
//     }, i[r].l = 1 * new Date(); a = s.createElement(o),
//       m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
//   })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
//   ga('create', 'UA-39983064-2', 'aletheia-clinic.com');
//   ga('send', 'pageview');
// })

router.afterEach((to, from) => {
  if ((to.path == '/reserve/reserves/search' && from.path != '/reserve/reserves/confirmreserve' && from.name && from.path != '/reserve/reserves/search')
    || (from.name == 'Search' && to.name == 'Search')) {
    router.go(0)
  }
})