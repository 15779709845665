import { URL } from "@/constants/index.js";
import axios from "@/config/axios.config";

// 用户登录接口
export async function userLogin(data, options = {}) {
	return axios({
		url: URL.userLogin,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 用户注册接口
export async function registerConfirm(data, options = {}) {
	return axios({
		url: URL.registerConfirm,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 设置密码
export async function registerComplete(data, options = {}) {
	return axios({
		url: URL.registerComplete,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 忘记密码
export async function lostpasswords(data, options = {}) {
	return axios({
		url: URL.lostpasswords,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 修改密码
export async function updatePassword(data, options = {}) {
	return axios({
		url: URL.updatePassword,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 查询会员信息
export async function memberInformation( options = {}) {
	return axios({
		url: URL.memberInformation,
		method: "post",
		...options,
	});
}

// 地址检索接口
export async function queryAddress(data, options = {}) {
	return axios({
		url: URL.queryAddress,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 会员地址保存接口
export async function modifySaveAddress(data, options = {}) {
	return axios({
		url: URL.modifySaveAddress,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 修改PC邮箱地址
export async function modifyPcMail(data, options = {}) {
	return axios({
		url: URL.modifyPcMail,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 修改密码接口
export async function modifyPassWord(data, options = {}) {
	return axios({
		url: URL.modifyPassWord,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 查询契约列表
export async function queryContractList( options = {}) {
	return axios({
		url: URL.queryContractList,
		method: "post",
		...options,
	});
}

// 礼品列表
export async function queryDiscountcampList( options = {}) {
	return axios({
		url: URL.queryDiscountcampList,
		method: "post",
		...options,
	});
}

// 查询会员预约列表
export async function queryConsultationList( options = {}) {
	return axios({
		url: URL.queryConsultationList,
		method: "post",
		...options,
	});
}

// 会员预约接口
export async function memberReverseSearch(data, options = {}) {
	return axios({
		url: URL.memberReverseSearch,
		method: "post",
		data,
		// params:data,
		...options,
	});
}

// 会员信息变更-会员手机号码变更接口
export async function modifyTel(data, options = {}) {
	return axios({
		url: URL.modifyTel,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 会员预约展示
export async function deleteConsultationShow(data, options = {}) {
	return axios({
		url: URL.deleteConsultationShow,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 会员预约取消接口
export async function deleteConsultation(data, options = {}) {
	return axios({
		url: URL.deleteConsultation,
		method: "post",
		data,
		params:data,
		...options,
	});
}

// 首页侧边列表接口
export async function leftimages( data,options = {}) {
	return axios({
		url: URL.leftimages,
		data,
		params:data,
		method: "post",
		...options,
	});
}

// 通知公告接口
export async function infoList(data, options = {}) {
	return axios({
		url: URL.infoList,
		data,
		method: "post",
		...options,
	});
}

// 详情接口
export async function showInformation(params, options = {}) {
	return axios({
		url: URL.showInformation,
		method: "get",
		params,
		...options,
	});
}

// 预约确认接口
export async function confirmReserve(data, options = {}) {
	return axios({
		url: URL.confirmReserve,
		method: "post",
		data,
		// params:data,
		...options,
	});
}

// 预约确认接口
export async function completereserve(data, options = {}) {
	return axios({
		url: URL.completereserve,
		method: "post",
		data,
		// params:data,
		...options,
	});
}

// 退出登陆的接口
export async function logout( options = {}) {
	return axios({
		url: URL.logout,
		method: "post",
		...options,
	});
}

// 获取节假日
export async function getHolidayList( options = {}) {
	return axios({
		url: '/holiday/getHolidayList',
		method: "post",
		...options,
	});
}