import { TIME_OUT } from "@/constants/config";
import Axios from "axios";
import store from "@/store";
import router from "../router";
var startRequest = 0
var endRequest = 0

const baseURL =
	process.env.VUE_APP_BASE_API
// process.env.NODE_ENV === 'development'
// 	? '/api'
// 	: process.env.VUE_APP_BASE_API

const axiosInstance = Axios.create({
	timeout: TIME_OUT * 1000,
	baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
	config => {
		if (!config.noLoading) {
			// console.log('请求开始请求开始')
			startRequest++
			store.commit("updateLoadingShow", true);
		}
		return config;
	},
	error => {
	}
);

axiosInstance.interceptors.response.use(
	response => {
		// console.log('请求结束请求结束请求结束请求结束')
		endRequest++
		let data = response.data;
		if (data.code == "202") {
			console.log('调用退出登录方法')
			store.commit("updateToken", null);
			router.replace({
				name:'Login'
			});
		}
		// console.log(startRequest, endRequest)
		if (startRequest == endRequest) {
			store.commit("updateLoadingShow", false);
		}
		return data
	},
	error => {
		endRequest++
		if (startRequest == endRequest) {
			store.commit("updateLoadingShow", false);
		}
	}
);
export default axiosInstance;
