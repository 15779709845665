import Vue from "vue";
import {
    Loading,
    Carousel,
    CarouselItem,
    Checkbox,
    CheckboxGroup,
    Select,
    Option,
    DatePicker,
  } from 'element-ui';
  
  Vue.use(Loading)
  Vue.use(Carousel)
  Vue.use(CarouselItem)
  Vue.use(Checkbox)
  Vue.use(CheckboxGroup)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(DatePicker)