// 用户登录接口
export const userLogin = `/member/userLogin`;
// 用户注册接口
export const registerConfirm = '/register/confirm'
// 设置密码
export const registerComplete = '/register/complete'
// 忘记密码
export const lostpasswords = '/register/lostpasswords'
// 修改密码
export const updatePassword = '/register/updatePassword'
// 查询会员信息
export const memberInformation = '/member/modify/query/memberInformation'
// 地址检索接口
export const queryAddress = '/member/modify/query/address'
// 会员地址保存接口
export const modifySaveAddress = '/member/modify/modifySaveAddress'
// 修改密码接口
export const modifyPassWord = '/member/modify/modifyPassWord'
// 修改PC邮箱地址
export const modifyPcMail = '/member/modify/modifyPcMail'
// 查询契约列表
export const queryContractList = '/contract/confirm/queryContractList'
// 礼品列表
export const queryDiscountcampList = '/contract/confirm/queryDiscountcampList'
// 查询会员预约列表
export const queryConsultationList = '/member/modify/queryConsultationList'
// 会员预约接口
export const memberReverseSearch = '/memberReverse/search'
// 预约确认接口
export const confirmReserve = '/memberReverse/confirmReserve'
// 提交预约
export const completereserve = '/memberReverse/completereserve'

// 会员信息变更-会员手机号码变更接口
export const modifyTel = '/member/modify/modifyTel'
// 会员预约展示
export const deleteConsultationShow = '/member/modify/deleteConsultationShow'
// 会员预约取消接口
export const deleteConsultation = '/member/modify/deleteConsultation'
// 首页侧边列表接口
export const leftimages = '/index/leftImages'
// 通知公告接口
export const infoList = '/index/infoList'
// 详情接口
export const showInformation = '/index/showInformation'
// 退出登陆接口
export const logout = '/member/logout'












































