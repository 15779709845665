<template>
  <div id="app">
    <loading />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import loading from "@/components/loading/loading.vue";
import { page } from 'vue-analytics'
export default {
  computed: {},
  name: "App",
  components: { loading },
  // watch:{
  //   $route(){
  //     this.track();
  //   }
  // },
  // methods: {
  //   track () {
  //     page('/')
  //   }
  // },
};
</script>

<style lang="scss">
.el-scrollbar {
  >.el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
