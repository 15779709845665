/**
 * 表格时间格式化 yyyy年MM月dd日 
 */
const formatDate = cellValue => {
  if (cellValue == null || cellValue == "") return "";
  cellValue = cellValue.replace(/-/g,"/");
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '年' + month + '月' + day + '日'
}

/**
 * 表格时间格式化 yyyy-MM-dd
 */
const formatYMd = cellValue => {
  if (cellValue == null || cellValue == "") return "";
  cellValue = cellValue.replace(/-/g,"/");
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return year + '-' + month + '-' + day
}

const formatDateYYYY = cellValue => {
  if (cellValue == null || cellValue == "") return "";
  cellValue = cellValue.replace(/-/g,"/");

  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '-' + month + '-' + day
}


export { formatDate, formatDateYYYY, formatYMd }
