import Vue from "vue";
import Vuex from "vuex";
import { STORAGE_KEY } from "@/constants/config";
import { indexApi } from "@/api/module.js";
// vuex本地持久化
import createPersistedState from "vuex-persistedstate";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);
const plugins = [
  createPersistedState({
    key: STORAGE_KEY,
    paths: [
      'loadingShow',
      'loginInfo',
      'token',
      'registerInfo',
      'passwordInfo',
      'confirmData',
      'shopInfo',
      'reserveId',
      'searchInfo',
      "datePicker",
      'IsPC',
      'memberInfo'
    ],
  }),
];
process.env.NODE_ENV !== "production" ? plugins.push(createLogger()) : null;

export default new Vuex.Store({
  plugins,// 刷新持久化处理
  state: {
    loadingShow: false,
    loginInfo: null,
    token: null,
    registerInfo: null,
    passwordInfo: null,
    confirmData: null, // 确认预约得请求参数
    shopInfo: null, // 店铺信息
    reserveId: '', // 变更得预约id
    searchInfo: {}, // 搜索侧边栏的缓存信息
    datePicker:{},
    IsPC: true, // 是否是pc
    memberInfo:null
  },
  mutations: {
    updateLoadingShow(state, loadingShow) {
      Vue.set(state, 'loadingShow', loadingShow)
    },
    updateLoginInfo(state, loginInfo) {
      Vue.set(state, 'loginInfo', loginInfo)
    },
    updateToken(state, token) {
      Vue.set(state, 'token', token)
    },
    updateRegisterInfo(state, registerInfo) {
      Vue.set(state, 'registerInfo', registerInfo)
    },
    updatePasswordInfo(state, passwordInfo) {
      Vue.set(state, 'passwordInfo', passwordInfo)
    },
    updateConfirmData(state, confirmData) {
      Vue.set(state, 'confirmData', confirmData)
    },
    updateShopInfo(state, shopInfo) {
      Vue.set(state, 'shopInfo', shopInfo)
    },
    updateReserveId(state, reserveId) {
      Vue.set(state, 'reserveId', reserveId)
    },
    updateSearchInfo(state, searchInfo) {
      Vue.set(state, 'searchInfo', searchInfo)
    },
    updateDatePicker(state, datePicker) {
      Vue.set(state, 'datePicker', datePicker)
    },
    checkDevice(state) {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      // this.IsPC = flag;
      Vue.set(state, 'IsPC', flag)
      // console.log(flag,'ispc');
    },
    updateMemberInfo(state, memberInfo) {
      Vue.set(state, 'memberInfo', memberInfo)
    },
  },
  actions: {
    getMemberInfo({ commit, state }) {
      indexApi
      .memberInformation({
        headers: { Authorization: state.token },
      })
      .then((res) => {
       commit('updateMemberInfo',res.data)
      });
    }
  },
  modules: {}
});
