import Vue from "vue";
import VueRouter from "vue-router";

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: {
      name: "Home",
    },
  },
  {
    path: "/authentication/login",
    name: "Login",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/login/login.vue"], resolve),
  },
  {
    path: "/member/regists",
    name: "Regists",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/regists/regists.vue"], resolve),
  },
  {
    path: "/member/regists/confirm",
    name: "Confirm",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/confirm/confirm.vue"], resolve),
  },
  {
    path: "/member/regists/complete",
    name: "Complete",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/complete/complete.vue"], resolve),
  },
  {
    path: "/member",
    name: "Member",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/member/member.vue"], resolve),
  },
  {
    path: "/member/lostpasswords/confirm",
    name: "LostpasswordsConfirm",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/lostpasswords/confirm.vue"], resolve),
  },
  {
    path: "/member/lostpasswords/complete",
    name: "LostpasswordsComplete",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/lostpasswords/complete.vue"], resolve),
  },
  {
    path: "/",
    name: "Home",
    meta: {
      title: "アリシアクリニック 会員サイト",
      // keepAlive:true
    },
    component: (resolve) => require(["@/views/home/home.vue"], resolve),
  },
  {
    path: "/application/listinformation",
    name: "Listinformation",
    meta: {
      title: "アリシアクリニック 会員サイト",
      // keepAlive:true
    },
    component: (resolve) => require(["@/views/listinformation/listinformation.vue"], resolve),
  },
  {
    path: "/application/showinformation",
    name: "Showinformation",
    meta: {
      title: "アリシアクリニック 会員サイト",
      // keepAlive:true
    },
    component: (resolve) => require(["@/views/showinformation/showinformation.vue"], resolve),
  },
  {
    path: "/contract",
    name: "Contract",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/contract/contract.vue"], resolve),
  },
  {
    path: "/contract/contracts/gift",
    name: "Gift",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/gift/gift.vue"], resolve),
  },
  {
    path: "/reserve",
    name: "Reserve",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/reserve/reserve.vue"], resolve),
  },
  {
    path: "/reserve/reserves/search",
    name: "Search",
    meta: {
      title: "アリシアクリニック 会員サイト",
      keepAlive:true
    },
    component: (resolve) => require(["@/views/search/search.vue"], resolve),
  },
  {
    path: "/reserve/reserves/confirmreserve",
    name: "Confirmreserve",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/confirmreserve/confirmreserve.vue"], resolve),
  },
  {
    path: "/reserve/reserves/completereserve",
    name: "Completereserve",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/completereserve/completereserve.vue"], resolve),
  },
  {
    path: "/reserve/reserves/confirmcancel",
    name: "Confirmcancel",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) =>
      require(["@/views/confirmcancel/confirmcancel.vue"], resolve),
  },
  {
    path: "/reserve/reserves/completecancel",
    name: "Completecancel",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) =>
      require(["@/views/completecancel/completecancel.vue"], resolve),
  },
  {
    path: "/member/members",
    name: "Members",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/members/members.vue"], resolve),
  },
  {
    path: "/member/members/showmail",
    name: "Showmail",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/showmail/showmail.vue"], resolve),
  },
  {
    path: "/member/members/number",
    name: "number",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) =>
      require(["@/views/number/number.vue"], resolve),
  },
  {
    path: "/member/members/showpass",
    name: "Showpass",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/showpass/showpass.vue"], resolve),
  },
  {
    path: "/member/members/changeAddress",
    name: "ChangeAddress",
    meta: {
      title: "アリシアクリニック 会員サイト",
    },
    component: (resolve) => require(["@/views/changeAddress/changeAddress.vue"], resolve),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-39983064-2',
  router
})

export default router;
